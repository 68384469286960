import React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from 'components/pages/home/Home';
import Services from 'components/pages/services/Services';
import Contact from 'components/pages/contact/Contact';
import About from 'components/pages/about/About';
import AcceptableUsePolicy from 'components/pages/aup/AcceptableUsePolicy';

const Body = () => {
  return (<Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/services" component={Services} />
    <Route exact path="/contact" component={Contact} />
    <Route exact path="/about" component={About} />
    <Route exact path="/legal/aup" component={AcceptableUsePolicy} />

    {/* External links */}
    <ExternalLink path="/apps" href="https://apps.bitsandspaces.com" />
    <ExternalLink path="/legal/terms-of-use" 
      href="https://app.termly.io/document/terms-and-conditions/c15c44ce-ee67-4694-811e-5199a1d860ce" />
    <ExternalLink path="/legal/privacy-policy" 
      href="https://app.termly.io/document/privacy-policy/38df1a52-8bdb-467c-86ec-4b7932072229" />
    <ExternalLink path="/legal/cookie-policy" 
      href="https://app.termly.io/document/cookie-policy/0d3ac39f-2f87-4147-96ce-4766ba965add" />
    <ExternalLink path="/legal/disclaimer" 
      href="https://app.termly.io/document/disclaimer/7b232f9b-f213-4371-9359-9760b595ee53" />
  </Switch>);
};

export default Body;

const ExternalLink = ({ path, href }) => (<Route path={path} component={() => { 
  window.location.href = href; 
  return null;
}}/>);
