import React from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, makeStyles } 
  from '@material-ui/core';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const MyDialog = ({ title, isOpen, setIsOpen, ...props }) => {
  const classes = useStyles();
  
  return (<Dialog open={isOpen} onClose={() => setIsOpen(false)}>
    <DialogTitle>
      <IconButton aria-label="close" className={classes.closeButton} 
        onClick={() => setIsOpen(false)}>
        <Close />
      </IconButton>
      {title}
    </DialogTitle>
    <Divider />
    <DialogContent>
      {props.children}
    </DialogContent>
  </Dialog>);
};

export default MyDialog;
