import React from 'react';
import workstation from 'images/workstation-side-1024.png';
import PageTitle from 'components/common/PageTitle';
import { Link, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import MediaQueries from 'components/common/MediaQueries';
import { useTextStyles } from 'utils/StylesUtil';

const useStyles = makeStyles(theme => ({
  imagePositioning: {
    marginTop: '4rem',
    marginBottom: '4rem'
  }
}));

const About = () => {
  const classes = useStyles();
  const textStyles = useTextStyles();
  const { xlMedia, lgMedia, mdMedia, smMedia } = MediaQueries(useMediaQuery);
  const mediaWidth = xlMedia || lgMedia ? '1024px' : mdMedia ? '800px' : smMedia ? 
    '500px' : '325px';

  return (<>
    <PageTitle title="About"/>
    <div className="ml-4 mr-4" style={{ marginBottom: '10rem' }}>
      <div className={classes.imagePositioning}>
        <img src={workstation} alt="workstation-side" style={{ width: mediaWidth }}
          className="bas-square-shadow bas-rounded-corners"  />
        <Typography variant="body2" className={`mt-3 ${textStyles.mainAccent}`}>
          Workstation
        </Typography>
        <Typography variant="subtitle2" className="mt-2">
          MacBook Pro, Linux laptop, and game dev/gaming machine
        </Typography>
        <Typography variant="body2">
          These are the different machines used for day-to-day development.
        </Typography>
      </div>
      <div className="mt-5 mb-5">
        <div className="mb-4">
          <Typography variant="h6" className="mb-3">Who and what is Bits and Spaces LLC?</Typography>
          <Typography variant="body1" className="mb-2">
            Bits and Spaces LLC is a one-man software company...really, it's more of a side project.
            This is going to sound cliche, but it's been a dream/goal. There's really no expectation to 
            make tons of money from this. The top reason is to fulfill a goal of owning my own software
            company, build software that I want to build, and release it to the public under a
            legitimate company name. Yes, Bits and Spaces LLC is a legitimate business that was 
            initially registered in the state of Hawaii.
          </Typography>
          <Typography variant="body1">
            The name came to me while eating and hanging out with my family. I was eating the bits and 
            pieces of food left on my plate and that's when the idea came.
            <span className="font-weight-bold">&nbsp;Bits&nbsp;</span>are the (binary) data and
            <span className="font-weight-bold">&nbsp;Spaces&nbsp;</span>are the different locations 
            that the data can exist in. Whether that be in volatile memory, non-volatile memory, or 
            somewhere in transit.
          </Typography>
        </div>
        <div className="mb-4">
          <Typography variant="h6" className="mb-3">Does the logo mean anything?</Typography>
          <Typography variant="body1">
            Yes, it does. The squares represent the spaces while the zeroes and one are the bits. The
            squares containing the zero values are colored differently from the square containing the
            number one, to show the two different possible binary values (on/off, true/false, etc.).
          </Typography>
        </div>
        <div className="mb-4">
          <Typography variant="body1" className="mb-2">
            The plan is to build apps (mostly mobile) and release them for free (as much as possible).
            These free apps will either contain ads/in-app purchases. I cover this, among other 
            things, in a 
            <Link className={textStyles.externalLinks} href="https://youtu.be/DvNYcXJeg7I">
              <span className={textStyles.mainAccent}>
                &nbsp;quick intro video.&nbsp;
              </span>
            </Link>
          </Typography>
          <Typography variant="body1">Happy coding and aloha!</Typography>
        </div>
      </div>
    </div>
  </>);
};

export default About;
