import React, { useState, useMemo, forwardRef } from 'react';
import { map } from 'lodash';
import { Link } from "react-router-dom";
import { Button, Drawer, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  drawerColor: {
    color: theme.palette.indigo.dark
  }
}));

const NavButtonLinks = ({ navLinks }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (<>
    <Button color="secondary" variant="outlined" size="small" onClick={() => setIsOpen(true)}>
      <MenuIcon />
    </Button>
    <Drawer anchor={'right'} open={isOpen} onClose={() => setIsOpen(false)} 
      className={classes.drawer}>
      <div role="presentation" onClick={() => setIsOpen(false)}>
        <List>
          {map(navLinks, navLink => <DrawerLinks key={navLink.to} navLink={navLink}
            classes={classes} />)}
        </List>
      </div>
    </Drawer>
  </>);
};

export default NavButtonLinks;

const DrawerLinks = ({ navLink, classes }) => {
  const customLink = useMemo(() => 
    forwardRef((props, ref) => 
      <Link ref={ref} to={navLink.to} {...props} />), [navLink.to]);

  return (<ListItem button key={navLink.to} component={customLink}>
    <ListItemIcon className={classes.drawerColor}>{navLink.icon}</ListItemIcon>
    <ListItemText className={classes.drawerColor} primary={navLink.name} />
  </ListItem>);
};
