import { makeStyles } from "@material-ui/core";

// This here to be used for pages that are under construction.
export const useUnderConstructionStyles = makeStyles(() => ({
  logo: {
    height: '40vmin',
    pointerEvents: 'none'
  },
  header: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)'
  }
}));

export const useTextStyles = makeStyles(theme => ({
  externalLinks: {
    color: theme.palette.astronaut.dark,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.astronaut.light,
      cursor: 'pointer'
    }
  },
  externalLinksLight: {
    color: '#fff',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.hippieBlue.light,
      cursor: 'pointer'
    }
  },
  mainAccent: {
    color: theme.palette.indigo.main
  }
}));
