import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useMediaQuery } from '@material-ui/core';
import MediaQueries from './MediaQueries';

const banner = 'https://bitsandspaces.com/bits-and-spaces-banner.png';

const useStyles = makeStyles(() => ({
  insetShadows: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    boxShadow: '0 -10px 50px 5px rgb(135, 135, 135, 0.8) inset'
  }
}));

const BitsAndSpacesBanner = () => {
  const classes = useStyles();
  const { xlMedia, lgMedia, mdMedia, smMedia } = MediaQueries(useMediaQuery);
  const mediaWidth = xlMedia ? '1500px' : lgMedia ? '1200px' : mdMedia ? '900px' : smMedia ? 
    '500px' : '400px';

  return (<div className="position-relative">
    <div className={classes.insetShadows}></div>
    <div className="text-center" style={{ backgroundColor: '#fff' }}>
      <img src={banner} alt="bits-and-spaces-banner" style={{ maxWidth: mediaWidth }} />
    </div>
  </div>);
};

export default BitsAndSpacesBanner;
