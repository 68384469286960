import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Content from 'components/common/Content';
import 'bootstrap/dist/css/bootstrap.min.css';
// These are the default that Material-UI typography relies on.
import "@fontsource/roboto/300.css" // Weight 300.
import "@fontsource/roboto/400.css" // Weight 400.
import "@fontsource/roboto/500.css" // Weight 500.
import "@fontsource/roboto/700.css" // Weight 700.
import './App.css';

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#304378',
    },
    secondary: {
      main: '#c6c9b8'
    },
    info: {
      main: '#171133'
    },
    success: {
      main: '#448f5c'
    },
    warning: {
      main: '#c17e24'
    },
    error: {
      main: '#f44336'
    },
    kangaroo: {
      dark: '#8a8c80',
      main: '#c6c9b8',
      light: '#d1d3c6'
    },
    hippieBlue: {
      dark: '#4a6f7d',
      main: '#6b9fb4',
      light: '#88b2c3'
    },
    astronaut: {
      dark: '#212e54',
      main: '#304378',
      light: '#596893'
    },
    indigo: {
      dark: '#284882',
      main: '#3a68bb',
      light: '#6186c8'
    },
    haiti: {
      dark: '#100b23',
      main: '#171133',
      light: '#45405b'
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <Router>
        <Content />
      </Router>
    </ThemeProvider>
  );
}

export default App;
