import React from 'react';
import { Link } from "react-router-dom";
import { Typography } from '@material-ui/core';
import logo from 'images/bits-and-spaces-logo-512.png';
import PageTitle from 'components/common/PageTitle';
import { useUnderConstructionStyles } from 'utils/StylesUtil';
import BitsAndSpacesBanner from 'components/common/BitsAndSpacesBanner';

const Services = () => {
  const underConstruction = useUnderConstructionStyles();

  return (<>
    <BitsAndSpacesBanner />
    <PageTitle title="Services"/>
    <header className={underConstruction.header} style={{ marginBottom: '10rem' }}>
      <img src={logo} className={underConstruction.logo} alt="logo" />
      <div className="p-4">
        <Typography variant="body1">
          The only services currently availble from Bits and Spaces LLC are the apps.<br />
          <Link to="/apps">
            Follow this link to check out the available apps.
          </Link>
        </Typography>
      </div>
    </header>
  </>);
};

export default Services;
