import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import pcTabletPhone from 'images/pc-tablet-phone.png';
import surfTracker808 from 'images/808-surf-tracker.png';
import PageTitle from 'components/common/PageTitle';
import { useTextStyles } from 'utils/StylesUtil';
import MyDialog from 'components/common/MyDialog';

const useStyles = makeStyles(theme => ({
  bg: {
    backgroundColor: theme.palette.astronaut.light
  }
}))

const Home = () => {
  const classes = useStyles();
  const textStyles = useTextStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (<>
    <MyDialog isOpen={isOpen} setIsOpen={setIsOpen} title="Attribution">
      Original, unedited image by
      <a href={"https://pixabay.com/users/mocho-156870/?utm_source=link-attribution" +
        "&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=314153"} target="_blank"
        rel="noreferrer">
        &nbsp;Mocho&nbsp;
      </a> 
      from 
      <a href={"https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral" +
        "&amp;utm_campaign=image&amp;utm_content=314153"} target="_blank" rel="noreferrer">
        &nbsp;Pixabay&nbsp;
      </a>
    </MyDialog>
    <PageTitle title="Bits and Spaces"/>
    <div className={`pl-4 pr-4 ${classes.bg}`} style={{ marginTop: '7rem', marginBottom: '7rem' }}>
      <img src={pcTabletPhone} alt="pc-tablet-phone" className="w-100" />
      <div className={`${textStyles.externalLinksLight} pb-3`} onClick={() => setIsOpen(true)}>
        <Typography variant="subtitle2">Click here for image attribution</Typography>
      </div>
    </div>
    <Grid container style={{ marginTop: '4rem', marginBottom: '10rem' }}>
      <Grid item md={6} xs={12} className="pl-4 pr-4">
        <img src={surfTracker808} alt="808-surf-tracker" 
          className="bas-rounded-corners bas-square-shadow w-75" />
      </Grid>
      <Grid item md={6} xs={12} className="pl-4 pr-4">
        <Typography variant="body2" className={`mt-3 ${textStyles.mainAccent}`}>
          808 Surf Tracker
        </Typography>
        <Typography variant="subtitle2" className="mt-2">
          First mobile app released by Bits and Spaces LLC
        </Typography>
        <Typography variant="body2">
          Released the 808 Surf Tracker app on the Google Play Store. First mobile app released 
          under the Bits and Spaces brand.
        </Typography>
      </Grid>
    </Grid>
  </>);
};

export default Home;
