import React from 'react';
import { makeStyles } from '@material-ui/core';
import Body from 'components/common/body/Body';
import Header from 'components/common/header/Header';
import Footer from 'components/common/footer/Footer';

const useStyles = makeStyles(theme => ({
  bgAndText: {
    backgroundColor: theme.palette.kangaroo.light,
    color: theme.palette.astronaut.dark
  }
}));

const Content = () => {
  const classes = useStyles();

  return (<div className={classes.bgAndText}>
    <Header />
    <Body />
    <Footer />
  </div>)
};

export default Content;
