import React from 'react';
import { Link, Typography } from '@material-ui/core';
import me from 'images/me.png';
import PageTitle from 'components/common/PageTitle';
import { Email, LinkedIn } from '@material-ui/icons';
import { useTextStyles } from 'utils/StylesUtil';

const Contact = () => {
  const classes = useTextStyles();

  return (<>
    <PageTitle title="Contact"/>
    <div className="d-flex justify-content-center ml-4 mr-4" 
      style={{ marginTop: '10rem', marginBottom: '10rem' }}>
      <div>
        <div className="ml-auto mr-auto" style={{ maxWidth: '300px' }}>
          <div className="bas-radial-shadow mt-4 mb-4" style={{ maxWidth: '300px' }}>
            <img src={me} alt="me" style={{ maxWidth: '300px', borderRadius: '50%' }} />
          </div>
          <Typography variant="body2" className={classes.mainAccent} 
            style={{ marginBottom: '70px' }}>
            Sal
          </Typography>
        </div>
        <div>
          <Typography variant="h6">
            <Email className="align-text-bottom" />
            :&nbsp;
            <Link className={classes.externalLinks} 
              href="mailto:contact@bitsandspaces.com">
              contact@bitsandspaces.com
            </Link>
          </Typography>
          <Typography variant="h6" className="bas-word-break-all">
            <LinkedIn className="align-text-bottom" />
            :&nbsp;
            <Link className={classes.externalLinks} 
              href="https://www.linkedin.com/company/bits-and-spaces-llc"
              target="_blank" rel="noreferrer">
              https://www.linkedin.com/company/bits-and-spaces-llc
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  </>);
};

export default Contact;
